import request from '@/utils/request'
import {CoachinDto} from '@/dto/CoachinDto';

const url = '/keep-fit/coach/'

export default class CoachApi {
  public async SaveAsync(coachDto: CoachinDto) {
    return new Promise((resolve, reject) => {
      request({
        url: `${url}`,
        method: 'post',
        data: coachDto
      }).then((res) => resolve(res.data)).catch((error) => reject(error))
    })
  }

  public async GetAsync(): Promise<CoachinDto> {
    return new Promise<CoachinDto>((resolve, reject) => {
      request({
        url: `${url}current`,
        method: 'get'
      }).then((res) => resolve(res.data)).catch((error) => reject(error))
    })
  }

  public async UpdateAsync(coachDto: CoachinDto) {
    return new Promise((resolve, reject) => {
      request({
        url: `${url}${coachDto.id}`,
        method: 'put',
        data: coachDto
      }).then((res) => resolve(res.data)).catch((error) => reject(error))
    })
  }

  public async getCoursesByApplyAsync(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      request({
        url: `${url}get-apply-courses`,
        method: 'get'
      }).then((res) => resolve(res.data)).catch((error) => reject(error))
    })
  }

  public async applyCourseAsync(courseId: string, price: string) {
    return new Promise((resolve, reject) => {
      request({
        url: `${url}apply`,
        method: 'post',
        data: {
          courseId,
          price
        }
      }).then((res) => resolve(res.data)).catch((error) => reject(error))
    })
  }

  public async getMyCourseListAsync(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      request({
        url: `${url}my-courses`,
        method: 'get'
      }).then((res) => resolve(res.data)).catch((error) => reject(error))
    })
  }

  public async getCoachInfoByIdAsync(id: string | Array<string | null>): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      request({
        url: `${url}${id}/coach-info`,
        method: 'get'
      }).then((res) => resolve(res.data)).catch((error) => reject(error))
    })
  }

  public async getListByState(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      request({
        url: `${url}getListByState`,
        method: 'get'
      }).then((res) => resolve(res.data)).catch((error) => reject(error))
    })
  }
}
