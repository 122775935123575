



































































































































import {Component, Vue} from 'vue-property-decorator';
import {CoachinDto} from '@/dto/CoachinDto';
import CoachApi from '@/api/coach'
import {upload} from '@/api/fileUpload'

import {areaList} from '@vant/area-data';

@Component({})
export default class Coachin extends Vue {

  // 手机号正则表达式
  private pattern = /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/
  private patternNumber = /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
  private api = new CoachApi()

  private isHave = false
  private maxSize = 1 * 1024 * 1024
  private fileZheng = new Array()
  private fileBei = new Array()
  private fileZhengMing = new Array()
  private fileZiZhi = new Array()

  private coachin: CoachinDto = {
    id: '',
    birthday: '',
    entryCertificate: '',
    faceImg: '',
    idCard1: '',
    idCard2: '',
    idNumber: '',
    idType: '中国居民身份证',
    location: '',
    name: '',
    phone: '',
    qualifications: '',
    remark: '',
    sex: undefined,
    understandCanal: '',
    workingYears: undefined,
    state: undefined
  };

  private showPicker = false;
  private minDate = new Date(1970, 0, 1);
  private maxDate = new Date();

  private showIdType = false;
  private columns = ['中国居民身份证', '台湾居民来往大陆通行证', '港澳居民来往大陆通行证', '护照'];

  private showArea = false;
  private areaList = areaList;

  private showTag = false;
  private channels = ['朋友介绍', '朋友圈转发', '网上搜索', '健身微信公众号/官网', '其他'];

  private async activated() {
    await this.getUserInfo()
  }

  private async getUserInfo() {
    const result = await this.api.GetAsync();
    if (result) {
      this.coachin = result
      if (this.coachin && this.coachin.name && this.coachin.phone) {
        this.isHave = true
      }
      if (this.coachin.idCard1) {
        this.fileZheng = [{url: `${this.coachin.idCard1}`}]
      }

      if (this.coachin.idCard2) {
        this.fileBei = [{url: `${this.coachin.idCard2}`}]
      }

      if (this.coachin.entryCertificate) {
        this.fileZhengMing = [{url: `${this.coachin.entryCertificate}`}]
      }

      if (this.coachin.qualifications) {
        this.fileZiZhi = [{url: `${this.coachin.qualifications}`}]
      }
    }
  }

  private async onSubmit(values: any) {
    if (this.coachin.idType === '中国居民身份证' && !this.patternNumber.test(this.coachin.idNumber)) {
      this.$toast('身份证格式不正确')
      return false
    }

    if (!this.coachin.id) {
      await this.api.SaveAsync(this.coachin)
      this.$toast('入驻申请提交成功，等待审核')
      location.reload()
    } else {
      this.coachin.state = 3
      await this.api.UpdateAsync(this.coachin)
      this.$toast('入驻申请重新提交成功，等待审核')
      location.reload()
    }
  }

  private onConfirm(value: any) {
    this.coachin.idType = value;
    this.showIdType = false;
  }

  private onDateConfirm(value: any) {
    const date = new Date(value);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    this.coachin.birthday = `${date.getFullYear()}-${month}-${day}`;
    this.showPicker = false;
  }

  private onAreaConfirm(values: any) {
    this.coachin.location = values
      .filter((item: any) => !!item)
      .map((item: any) => item.name)
      .join('/');
    this.showArea = false;
  }

  private onTagConfirm(value: any) {
    this.coachin.understandCanal = value;
    this.showTag = false;
  }

  private handleBack() {
    this.isHave = false
  }

  private onOversize(file: any) {
    this.$toast('文件大小不能超过 1mb')
  }

  private async afterRead(file: any, type: number) {
    file.status = 'uploading';
    file.message = '上传中...';

    const formData = new FormData()
    formData.append('file', file.file)
    const result = await upload(formData)
    if (result) {
      file.status = '';
      file.message = '上传成功';
      switch (type) {
        case 1:
          this.fileZheng = [file]
          this.coachin.idCard1 = result.fullName
          break;
        case 2:
          this.fileBei = [file]
          this.coachin.idCard2 = result.fullName
          break;
        case 3:
          this.fileZhengMing = [file]
          this.coachin.entryCertificate = result.fullName
          break;
        case 4:
          this.fileZiZhi = [file]
          this.coachin.qualifications = result.fullName
          break;
      }
    }
  }

  private handleDeleteImg(file: any, type: number) {
    switch (type) {
      case 1:
        this.fileZheng = []
        this.coachin.idCard1 = ''
        break;
      case 2:
        this.fileBei = []
        this.coachin.idCard2 = ''
        break;
      case 3:
        this.fileZhengMing = []
        this.coachin.entryCertificate = ''
        break;
      case 4:
        this.fileZiZhi = []
        this.coachin.qualifications = ''
        break;
    }
  }
};
